export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'image',
    label: 'field.image',
  },
  {
    key: 'itemCode',
    label: 'field.itemCode',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'itemName',
    sortable: true,
    sortField: 'itemName',
    label: 'field.itemName',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'requestQuantity',
    label: 'field.requestQuantity',
  },
  {
    key: 'issueQuantity',
    label: 'field.issueQuantity',
  },
  {
    key: 'remark',
    label: 'field.remark',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
];
