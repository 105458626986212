<template>
  <b-modal
    id="modal-stock-request-line"
    centered
    hide-footer
    :title="$t('general.requestedItem')"
    size="xl"
    @hide="isShow = false"
  >
    <b-row class="pb-1">
      <b-col md="6"> {{ $t("field.stockRequestCode") }} : {{ item.code }} </b-col>
      <b-col md="6">
        {{ $t("field.state") }} :
        <span class="text-capitalize">{{ item.state }}</span>
      </b-col>
      <b-col md="6" v-if="item.requestPurpose">
        {{ $t("field.requestPurpose") }} : {{ item.requestPurpose.name }}
      </b-col>
      <b-col md="6" v-if="item.requestSection">
        {{ $t("field.requestSection") }} : {{ item.requestSection.name }}
      </b-col>
      <b-col md="6">
        {{ $t("field.requestedDate") }} : {{ item.createdAt | formatDate }}
      </b-col>
    </b-row>
    <n-table :fields="fields" :items="items" type="stock-request">
      <template #cell(image)="data">
        <img
          class="cursor-pointer"
          v-if="data.item.item && data.item.item.image"
          :width="`50px`"
          :src="data.item.item.image"
          :alt="data.item.item.name"
          @click="previewImage(data.item.item.image)"
        />
      </template>
      <template #cell(itemName)="data">
        <span v-if="data.item.item">
          {{ data.item.item.name }}
        </span>
      </template>
      <template #cell(itemCode)="data">
        <span v-if="data.item.item">
          {{ data.item.item.itemCode }}
        </span>
      </template>
      <template #cell(requestQuantity)="data">
        <span v-if="data.item.requestQuantity">
          {{ data.item.requestQuantity }}
        </span>
        <span v-else> - </span>
      </template>
      <template #cell(issueQuantity)="data">
        <span v-if="data.item.issueQuantity">
          {{ data.item.issueQuantity }}
        </span>
        <span v-else> - </span>
      </template>
    </n-table>
    <b-row v-if="item.remark">
      <b-col md="12"> {{ $t("field.remark") }} : {{ item.remark }} </b-col>
    </b-row>
    <hr v-if="item.remark" />
    <b-row class="pb-1">
      <b-col md="6" v-if="item.requestedBy">
        {{ $t("field.requestedBy") }} : {{ item.requestedBy.name }}
        <br />
        {{ $t("field.requestedDate") }} : {{ item.createdAt | formatDate }}
      </b-col>
      <b-col md="6" v-if="item.reviewedBy">
        {{ $t("field.reviewedBy") }} : {{ item.reviewedBy.name }}
        <br />
        {{ $t("field.reviewedDate") }} : {{ item.reviewedAt | formatDate }}
      </b-col>
      <b-col md="6" v-if="item.issuedBy">
        {{ $t("field.issuedBy") }} : {{ item.issuedBy.name }}
        <br />
        {{ $t("field.issuedDate") }} : {{ item.issuedAt | formatDate }}
      </b-col>
      <b-col md="6" v-if="item.receivedBy">
        {{ $t("field.receivedBy") }} : {{ item.receivedBy.name }}
        <br />
        {{ $t("field.receivedDate") }} : {{ item.receivedAt | formatDate }}
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
} from "bootstrap-vue";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";

export default {
  components: {
    BRow,
    BCol,
    NTable,
  },
  data() {
    return {
      item: {},
      items: [],
    };
  },
  methods: {
    previewImage(image) {
      const preview = this.$imagePreview({
        initIndex: 0,
        images: [image],
        zIndex: 9999,
        isEnableDownloadImage: false,
        isEnableImagePageIndicator: false,
        isEnableBlurBackground: true,
        onClose: () => {},
      });
    },
    show(item) {
      this.item = { ...item };
      this.items = [...item.lines];
      this.$bvModal.show("modal-stock-request-line");
    },
    hide() {
      this.isShow = false;
      this.$bvModal.hide("modal-stock-request-line");
    },
    stockInOut(item) {
      this.$refs.stockInOutModal.show(item.id, item?.availableItem?.itemId);
    },
  },
  setup() {
    const fields = TableFields;

    return {
      fields,
    };
  },
};
</script>