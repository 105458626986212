export default [
  {
    key: 'companyId',
    label: 'field.company',
    type: 'nAsynSingleSelection',
    repository: 'company',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    type: 'nAsynSingleSelection',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'itemCategoryId',
    label: 'field.itemCategory',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'itemCategory',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'itemSectionId',
    label: 'field.itemSection',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'itemSection',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'itemTypeId',
    label: 'field.itemType',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'itemType',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'unitId',
    label: 'field.unit',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'unit',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'itemCode',
    label: 'field.itemCode',
    type: 'text',
    operator: '%',
  },
  {
    key: 'name',
    label: 'field.itemName',
    type: 'text',
    operator: '%',
  },
  {
    key: 'fromQuantity',
    searchField: 'quantity',
    label: 'field.fromQuantityInHand',
    type: 'currency',
    operator: '>=',
  },
  {
    key: 'toQuantity',
    searchField: 'quantity',
    label: 'field.toQuantityInHand',
    type: 'currency',
    operator: '<=',
  },
  {
    key: 'fromReorderLevel',
    searchField: 'reorderLevel',
    label: 'field.fromReorderLevel',
    type: 'currency',
    operator: '>=',
  },
  {
    key: 'toReorderLevel',
    searchField: 'reorderLevel',
    label: 'field.toReorderLevel',
    type: 'currency',
    operator: '<=',
  },
  {
    key: 'stockType',
    label: 'field.stock',
    type: 'radio',
    options: [
      { text: 'field.inStock', value: '1' },
      { text: 'field.lowStock', value: '2' },
      { text: 'field.outOfStock', value: '3' },
    ],
    cols: 'auto',
  },
  {
    key: 'isEnable',
    label: 'field.status',
    type: 'radio',
    cast: 'boolean',
    options: [
      { text: 'status.active', value: '1' },
      { text: 'status.inactive', value: '0' },
    ],
    cols: 'auto',
  },
];
