export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'code',
    label: 'field.code',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'state',
    label: 'field.state',
    thClass: 'text-left',
    tdClass: 'text-left text-capitalize',
  },
  {
    key: 'remark',
    label: 'field.remark',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'requestSection',
    label: 'field.requestSection',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'requestPurpose',
    label: 'field.requestPurpose',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.requestedDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'requestedBy',
    label: 'field.requestedBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'reviewedAt',
    sortable: true,
    sortField: 'reviewedAt',
    label: 'field.reviewedDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'reviewedBy',
    label: 'field.reviewedBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'issuedAt',
    sortable: true,
    sortField: 'issuedAt',
    label: 'field.issuedDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'issuedBy',
    label: 'field.issuedBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'receivedAt',
    sortable: true,
    sortField: 'receivedAt',
    label: 'field.receivedDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'receivedBy',
    label: 'field.receivedBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
