<template>
  <div>
    <b-modal
      id="modal-stock-request"
      centered
      hide-footer
      :title="modalTitle"
      size="xl"
      @hide="isShow = false"
    >
      <!-- Table Container Card -->
      <b-card no-body class="mb-0">
        <div class="m-1">
          <b-row>
            <b-col>
              <label>{{ $t("general.entries") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>
          </b-row>
        </div>
        <div>
          <n-table
            @sort-changed="sortChanged"
            :fields="fields"
            :items="items"
            :busy="loading"
            :current-page="params.page"
            :per-page="$store.state.pagination.perPage"
            :total="total"
            :type="permissionType"
            :updatable="false"
            :view-modal="true"
            @view="viewStock"
          >
            <template #cell(requestSection)="data">
              <b-link
                class="link-underline"
                :to="{
                  name: 'view-request-section',
                  params: { id: data.item.requestSection.id },
                }"
                v-if="data.item.requestSection"
              >
                {{ data.item.requestSection.name }}
              </b-link>
            </template>
            <template #cell(requestPurpose)="data">
              <b-link
                class="link-underline"
                :to="{
                  name: 'view-request-purpose',
                  params: { id: data.item.requestPurpose.id },
                }"
                v-if="data.item.requestPurpose"
              >
                {{ data.item.requestPurpose.name }}
              </b-link>
            </template>
            <template #cell(requestedBy)="data">
              <b-link
                class="link-underline"
                :to="{
                  name:
                    data.item.requestedBy.typeId == 2
                      ? 'view-employee'
                      : 'view-administrator',
                  params: { id: data.item.requestedBy.id },
                }"
                v-if="data.item.requestedBy"
              >
                {{ data.item.requestedBy.name }}
              </b-link>
            </template>
            <template #cell(reviewedAt)="data">
              {{ data.item.reviewedAt | formatDate }}
            </template>
            <template #cell(reviewedBy)="data">
              <b-link
                class="link-underline"
                :to="{
                  name:
                    data.item.reviewedBy.typeId == 2
                      ? 'view-employee'
                      : 'view-administrator',
                  params: { id: data.item.reviewedBy.id },
                }"
                v-if="data.item.reviewedBy"
              >
                {{ data.item.reviewedBy.name }}
              </b-link>
            </template>
            <template #cell(issuedAt)="data">
              {{ data.item.issuedAt | formatDate }}
            </template>
            <template #cell(issuedBy)="data">
              <b-link
                class="link-underline"
                :to="{
                  name:
                    data.item.issuedBy.typeId == 2
                      ? 'view-employee'
                      : 'view-administrator',
                  params: { id: data.item.issuedBy.id },
                }"
                v-if="data.item.issuedBy"
              >
                {{ data.item.issuedBy.name }}
              </b-link>
            </template>
            <template #cell(receivedAt)="data">
              {{ data.item.receivedAt | formatDate }}
            </template>
            <template #cell(receivedBy)="data">
              <b-link
                class="link-underline"
                :to="{
                  name:
                    data.item.receivedBy.typeId == 2
                      ? 'view-employee'
                      : 'view-administrator',
                  params: { id: data.item.receivedBy.id },
                }"
                v-if="data.item.receivedBy"
              >
                {{ data.item.receivedBy.name }}
              </b-link>
            </template>
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <slot name="front-button" :item="data.item"></slot>
                <b-button
                  @click="viewStock(data.item)"
                  v-b-tooltip.hover
                  :title="$t('general.stock')"
                  variant="primary"
                  pill
                  size="sm"
                >
                  <feather-icon icon="PackageIcon" />
                </b-button>
              </div>
            </template>
          </n-table>
          <n-pagination
            ref="pagination"
            class="mt-2"
            :total="total"
            :per-page="$store.state.pagination.perPage"
            :page="params.page"
            @change="list"
          ></n-pagination>
        </div>
      </b-card>
    </b-modal>
    <stock-request-line-modal
      ref="stockRequestLineModal"
    ></stock-request-line-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import StockRequestLineModal from "@/views/stockRequest/stockRequestLine/Modal";

const StockRequestRepository = Repository.get("stockRequest");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    BFormGroup,
    BInputGroup,
    BBadge,
    BLink,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    StockRequestLineModal,
  },
  watch: {
    perPage(value) {
      this.list();
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      modalTitle: "",
      params: {
        page: 1,
        order: "",
        sort: "",
        search: "",
        itemId: "",
      },
    };
  },
  methods: {
    show(item) {
      this.modalTitle = this.$t("general.stockRequestOfItem", {
        item: `${item.name} (${item.itemCode})`,
      });

      this.params.itemId = item.id;
      this.$bvModal.show("modal-stock-request");
      this.isShow = true;
      this.list();
    },
    hide() {
      this.params = {
        page: 1,
        order: "",
        sort: "",
        search: "",
        itemId: "",
      };
      this.isShow = false;
      this.$bvModal.hide("modal-stock-request");
    },
    list(page = 1) {
      this.params.page = page;
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.getData();
    },
    getData() {
      if (!this.params.itemId) {
        return;
      }
      this.loading = true;
      const params = {
        ...this.params,
        queries: [
          {
            searchby: "item_id",
            searchoperator: "=",
            search: this.params.itemId,
          },
          {
            searchby: "state",
            searchoperator: "in",
            search: ["requested", "approved", "prepared"],
          },
        ],
      };
      StockRequestRepository.index(params)
        .then((response) => {
          const data = response.data.data;
          this.items = [...data.list];
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    viewStock(item) {
      this.$refs.stockRequestLineModal.show({
        ...item,
      });
    },
  },
  setup() {
    const fields = TableFields;
    const permissionType = "stock-request";

    return {
      fields,
      permissionType,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
