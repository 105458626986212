var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-stock-request","centered":"","hide-footer":"","title":_vm.modalTitle,"size":"xl"},on:{"hide":function($event){_vm.isShow = false}}},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-1"},[_c('b-row',[_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("general.entries")))]),_c('v-select',{staticClass:"per-page-selector d-inline-block ml-50 mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1),_c('div',[_c('n-table',{attrs:{"fields":_vm.fields,"items":_vm.items,"busy":_vm.loading,"current-page":_vm.params.page,"per-page":_vm.$store.state.pagination.perPage,"total":_vm.total,"type":_vm.permissionType,"updatable":false,"view-modal":true},on:{"sort-changed":_vm.sortChanged,"view":_vm.viewStock},scopedSlots:_vm._u([{key:"cell(requestSection)",fn:function(data){return [(data.item.requestSection)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
                name: 'view-request-section',
                params: { id: data.item.requestSection.id },
              }}},[_vm._v(" "+_vm._s(data.item.requestSection.name)+" ")]):_vm._e()]}},{key:"cell(requestPurpose)",fn:function(data){return [(data.item.requestPurpose)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
                name: 'view-request-purpose',
                params: { id: data.item.requestPurpose.id },
              }}},[_vm._v(" "+_vm._s(data.item.requestPurpose.name)+" ")]):_vm._e()]}},{key:"cell(requestedBy)",fn:function(data){return [(data.item.requestedBy)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
                name:
                  data.item.requestedBy.typeId == 2
                    ? 'view-employee'
                    : 'view-administrator',
                params: { id: data.item.requestedBy.id },
              }}},[_vm._v(" "+_vm._s(data.item.requestedBy.name)+" ")]):_vm._e()]}},{key:"cell(reviewedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.reviewedAt))+" ")]}},{key:"cell(reviewedBy)",fn:function(data){return [(data.item.reviewedBy)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
                name:
                  data.item.reviewedBy.typeId == 2
                    ? 'view-employee'
                    : 'view-administrator',
                params: { id: data.item.reviewedBy.id },
              }}},[_vm._v(" "+_vm._s(data.item.reviewedBy.name)+" ")]):_vm._e()]}},{key:"cell(issuedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.issuedAt))+" ")]}},{key:"cell(issuedBy)",fn:function(data){return [(data.item.issuedBy)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
                name:
                  data.item.issuedBy.typeId == 2
                    ? 'view-employee'
                    : 'view-administrator',
                params: { id: data.item.issuedBy.id },
              }}},[_vm._v(" "+_vm._s(data.item.issuedBy.name)+" ")]):_vm._e()]}},{key:"cell(receivedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.receivedAt))+" ")]}},{key:"cell(receivedBy)",fn:function(data){return [(data.item.receivedBy)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
                name:
                  data.item.receivedBy.typeId == 2
                    ? 'view-employee'
                    : 'view-administrator',
                params: { id: data.item.receivedBy.id },
              }}},[_vm._v(" "+_vm._s(data.item.receivedBy.name)+" ")]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._t("front-button",null,{"item":data.item}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('general.stock'),"variant":"primary","pill":"","size":"sm"},on:{"click":function($event){return _vm.viewStock(data.item)}}},[_c('feather-icon',{attrs:{"icon":"PackageIcon"}})],1)],2)]}}],null,true)}),_c('n-pagination',{ref:"pagination",staticClass:"mt-2",attrs:{"total":_vm.total,"per-page":_vm.$store.state.pagination.perPage,"page":_vm.params.page},on:{"change":_vm.list}})],1)])],1),_c('stock-request-line-modal',{ref:"stockRequestLineModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }