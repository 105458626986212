export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'stockLocation',
    label: 'field.location',
  },
  {
    key: 'code',
    sortable: true,
    sortField: 'code',
    label: 'field.binCode',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'availableQuantity',
    label: 'field.quantityInHand',
  },
  {
    key: 'remark',
    label: 'field.remark',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
